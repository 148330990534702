
   .card-container {
    position: relative;
    max-width: 100%; /* Ensures that the container doesn't exceed the width of its parent */
    height: 390px; /* Fixed height, you can also make this responsive if needed */
    background-size: cover;
    /* overflow: hidden;  */
  }
  
  .character-layer {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 100%; /* Initially offscreen */
    left: 0;
    opacity: 0; /* Start invisible */
    transition: top 1.5s, opacity 1.5s;
  }
  
  .character-layer.active {
    animation: slide-in-out 5.8s ease-in-out infinite;
  }
  
  /* @keyframes slide-in-out {
    0%, 25% {
      top: 100%;
      opacity: 0;
    }
    25%, 75% { 
      top: 0;
      opacity: 1;
    }
    75%, 100% { 
      top: -100%;
      opacity: 0;
      transform: rotate(10deg);
    }
  } */
  @keyframes slide-in-out {
    0%, 25% {
      top: 0%;
      /* left: -100%; */
      opacity: 0;
    }
    25%, 75% { 
      left: 0;
      opacity: 1;
    }
    75%, 100% { 
      /* left: 100%; */
      top: 0%;
      opacity: 0;
      /* transform: rotate(10deg); */
    }
  }
  
  .character-layer img {
    width: 100%;
    height: auto;
    object-fit: cover; 
  }
  
  /* Responsive adjustments for smaller screens */
  @media (max-width: 600px) {
    .card-container {
      height: 280px; /* Adjust height for smaller screens */
    }
  }
  