.slider-nft-item{
    max-height: 411px;
    transition: all 0.15s ease-in-out;
    padding-bottom: 10px;
    overflow: hidden;
}

.slider-nft-item:hover{
    border-color: #6f70ef;
    -webkit-box-shadow: 0px 3px 17px -2px rgba(111,112,239,1);
-moz-box-shadow: 0px 3px 17px -2px rgba(111,112,239,1);
box-shadow: 0px 3px 17px -2px rgba(111,112,239,1);
}

.slider-nft-item .ant-card-body{
    padding: 15px
}

.slider-nft-item .ant-card-body .card-info-row-1 .item-name{
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    color: #fff !important;
    font-family: 'Inter', sans-serif;
}

.slider-nft-item .ant-card-body .card-info-row-1 .collection-name{
    font-weight: 600
}

.slider-nft-item .card-info-row-2 .meta-name{
    color: #fff;
    font-size: 12px;
    font-weight: 600;
    
}

.slider-nft-item .card-info-row-2 .meta-val{
    color: #fff;
    font-size: 16px !important;
    font-weight: 600;
}

.slider-nft-item{
    border-radius: 20px;
    background: linear-gradient(137deg, rgba(255, 255, 255, 0.00) 0%, rgba(255, 255, 255, 0.15) 90%);
    box-shadow: 0px 4px 49px 0px rgba(0, 7, 72, 0.12);
    backdrop-filter: blur(12.5px);
}

.slider-nft-item .nft-img{
    height: 250px;
    width: 400px;
    object-fit: cover;
    border-radius: 15px !important;
}

.slider-nft-item .nft-img-wrapper{
    height: 250px;
    width: 400px;
    object-fit: cover;
    max-width: 100%;
    overflow: hidden;
    border-radius: 15px;
    transition: all 0.15s ease-in-out;
    position: relative;
}

.slider-nft-item:hover .nft-img-wrapper{
    /* height: 200px; */
}

.slider-nft-item .nft-img-wrapper::before{
    transition: all 0.35s ease-in-out;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 120%;
    width: 120%;
    background: transparent;
    overflow: hidden;
    border-radius: 15px;
}

.slider-nft-item:hover .nft-img-wrapper::before{
/*     background: red; */
background: rgb(15,0,56);
background: radial-gradient(circle, rgba(15,0,56,0.1012998949579832) 47%, rgba(0,0,0,0.894016981792717) 100%);
}

.slider-nft-item .btn-row{
    display: none;
    opacity: 0;
    transition: all 0.5s ease-in-out !important;
    overflow: hidden;
}

.slider-nft-item:hover .btn-row{
    display: block;
    opacity: 1;
}

/* .processing .nft-img-wrapper{
    height: 200px;
} */
.processing.slider-nft-item .btn-row{
    display: block;
    opacity: 1;
}
.nft-card-button {
    margin-bottom: 200px !important;
}