:root {
  --primary-color: #6F70EF;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Inter', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  font-family: 'Inter', sans-serif;
}

/* boostrap grid customization */
@media (min-width: 1400px){
  .container{
      max-width: 1140px;
  }
  
}

/* typography */

h1,
.home-cover h1 > div{
  font-size: 45px;
}

h2 {
  font-size: 37px;
}

p,
div > p{
  font-size: 16px;
}

/* section specific */

main section {
  margin-bottom: 60px;
}

section h1.section-title {
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 70px;
  color: var(--primary-color);
}

section h2.section-title{
  font-size: 37px;
  font-style: normal;
  font-weight: 700;
  line-height: 70px;
  color: var(--primary-color);
}

section .content-paragraph-type-1 {
  color: #fff;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 30.6px;
}

section p {
  margin-bottom: 30px;
}

.transparent-btn{
  background-color: transparent;
}

@media (max-width: 768px){
  main section{
    margin-bottom: 40px;
  }

  .home-cover h1{
    margin-bottom: 20px;
  }

  .home-cover h1 > div{
    font-size: 30px;
    line-height: 50px;
  }

  .home-cover h1 .hilighted{
    font-size: 38px !important;
  }
}

/* scrollbar css */
/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #4648D8 #DFE9EB;
}

/* Chrome, Edge and Safari */
*::-webkit-scrollbar {
  width: 10px;
  width: 10px;
}
*::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: #111;
}

*::-webkit-scrollbar-track:hover {
  background-color: #000;
}

*::-webkit-scrollbar-track:active {
  background-color: #000;
}

*::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #4648D8;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #3D6CD8;
}

*::-webkit-scrollbar-thumb:active {
  background-color: #3D6CD8;
}
