.minting-card {
    border-radius: 20px;
    /* border: 2px solid #3e3e3e; */
    /* background: linear-gradient(137deg, rgba(255, 255, 255, 0.00) 0%, rgba(255, 255, 255, 0) 100%); */
    /* background-color: rgba(255, 255, 255, 0.1); */
    box-shadow: 0px 4px 49px 0px rgba(0, 7, 72, 0.12);
    backdrop-filter: blur(150px);
}

.minting-card .no-of-items {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 26.8px;
}

.minting-card .no-of-items span:nth-child(2) {
    margin-left: 10px;
    margin-right: 10px;
}

.minting-card .input-wrapper button span {
    font-size: 15px;
    line-height: 20px;
}

.minting-card .input-wrapper input{
    text-align: center;
}

.minting-card .minting-img-wrapper img{
    border-radius: 15px;
}

.minting-card .total-row{
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26.8px;
}

.minting-card .cta-btn-row button{
    padding: 10px 30px !important;
    height: inherit !important;
}

.minting-card .cta-btn-row button span{
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

@media (max-width: 480px) {
    .minting-card{
        margin-bottom: 40px;
    }
}

.nft-img {
    transition: transform 0.5s ease-in-out;
  }
.discount-value {
    font-size: 12px;
    line-height: 10px;
}
.total-amounts {
    font-weight: 800 !important;
}